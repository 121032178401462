import { Form, Row, Col, FormSelect } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { error as errorAlert, successAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { getAccounts } from '../../resources/api/accounts';
import { createTitle, getBatchRefs, updateTitle } from '../../resources/api/titles';
import { DateSelector } from '../../components/datetimepicker';
import moment from 'moment';
import { sortFunction } from '../../components/resources';
import FileInput from '../../components/files';
import SelectInput from '../../components/select';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('./view-titles').TitleObject} props.details
 * @param {(details: import('./view-titles').TitleObject) => void} props.onSubmit
 */
const TitleForm = ({ details: titleObject, action, onSubmit }) => {


    const [details, setDetails] = useState(titleObject);
    const [regPropChanged, setRegPropChange] = useState(action === "edit");

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const [accounts, setAccounts] = useState([]);

    const [isNewBatch, setNewBatch] = useState(false);
    const [batchRefs, setBatchRefs] = useState([]);



    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "edit") {
            promise = updateTitle(details, details.id);
        } else {
            promise = createTitle({
                ...details,
                batch_ref: isNewBatch ? "new" : details.batch_ref
            });
        }

        promise.then(({ message, title }) => {
            successAlert(message);
            onSubmit(title);
        })
            .catch(err => {
                errorAlert(err);
                setSubmitting(false);
            })
    }

    useEffect(() => {
        getAccounts()
            .then(({ accounts }) => {
                setAccounts(accounts.sort((a, b) => sortFunction(a, b, "title")));
            })
            .catch(err => errorAlert(`Could not get a list of accounts. ${err}`))
    }, [])

    useEffect(() => {

        if (action === "edit") return;

        if (!details.account_id) {
            setBatchRefs([]);
            return;
        }

        getBatchRefs(details.account_id)
            .then(({ batch_refs }) => {
                setBatchRefs(batch_refs.map(r => ({
                    id: r.ref,
                    title: r.ref,
                    description: `Created on ${moment(r.date_added).format("DD MMM YYYY")}`
                })));
                // setDetails(d => ({ ...d, batch_ref: "" }));
            })
            .catch((e) => errorAlert(e))
    }, [details.account_id, action])


    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Title Details</h4>

            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Account</Label>
                    <FormSelect
                        value={details.account_id}
                        onChange={e => setDetails({ ...details, account_id: e.currentTarget.value, batch_ref: "" })}
                        required
                        disabled={action === "edit"}
                    >
                        <option value="">Select an account</option>
                        {accounts.map(a => <option key={a.id} value={a.id}>{a.title}</option>)}

                    </FormSelect>
                    <Feedback type="invalid">
                        Choose an account.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Instruction Date</Label>
                    <DateSelector
                        value={details.instruction_date}
                        onChange={date => setDetails(d => ({ ...d, instruction_date: date }))}
                        minDate={moment().subtract(2, "months").format("YYYY-MM-DD")}
                        maxDate={0}
                        required
                    />
                    <Feedback type="invalid">
                        A date must be selected
                    </Feedback>
                </Col>
            </Row>

            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Batch Reference</Label>

                    {(!isNewBatch && (batchRefs.length > 0)) &&
                        <SelectInput
                            value={[details.batch_ref]}
                            onChange={([batch_ref]) => setDetails(d => ({ ...d, batch_ref: batch_ref || "" }))}
                            options={batchRefs}
                            required
                            disabled={(action === "edit") || isNewBatch || (batchRefs.length === 0)}
                            placeholder={"Choose a reference or check generate one below"}
                        />
                    }

                    {(action === "create") &&
                        <div className="mb-3">
                            <Form.Check type="checkbox" id="newbatchcheck">
                                <Form.Check.Input type="checkbox" checked={isNewBatch} onChange={() => {
                                    setNewBatch(b => !b);
                                    setDetails(d => ({ ...d, batch_ref: "" }))
                                }} />
                                <Form.Check.Label>Generate New Batch Reference</Form.Check.Label>
                            </Form.Check>
                        </div>
                    }

                </Col>
            </Row>


            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Customer Name</Label>
                    <Control
                        value={details.customer}
                        onChange={e => setDetails({
                            ...details, customer: e.currentTarget.value,
                            registered_proprietor: regPropChanged ? details.registered_proprietor : e.currentTarget.value
                        })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        Customer name is required.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Registered Proprietor</Label>
                    <Control
                        value={details.registered_proprietor}
                        onChange={e => {
                            setDetails({ ...details, registered_proprietor: e.currentTarget.value });
                            setRegPropChange(true);
                        }}
                    />
                </Col>
            </Row>

            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Loan Amount</Label>
                    <Control
                        type="number"
                        min={1000}
                        step={0.05}
                        value={details.loan_amount}
                        onChange={e => setDetails({ ...details, loan_amount: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        Loan amount cannot be below 1000 and must be in steps of 0.05.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Transaction Type</Label>
                    <FormSelect
                        value={details.transaction_type}
                        onChange={e => setDetails({ ...details, transaction_type: e.currentTarget.value })}
                        required
                    >
                        <option value="">Choose an option</option>
                        <option value="mortgage">Mortgage</option>
                        <option value="transfer">Transfer</option>
                        <option value="special">Special</option>
                        <option value="debenture">Debenture</option>
                        <option value="variation">Variation</option>
                        <option value="verification">Verification</option>
                        <option value="caveated">Caveated</option>
                        <option value="condominium">Condominium</option>
                        <option value="mortgage-transfer">Mortgage Transfer</option>
                    </FormSelect>
                    <Feedback type="invalid">
                        Please select an option
                    </Feedback>
                </Col>
            </Row>

            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Land Office</Label>
                    <Control
                        value={details.land_office}
                        onChange={e => setDetails({
                            ...details,
                            land_office: e.currentTarget.value
                        })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        This field is required.
                    </Feedback>
                </Col>
            </Row>

            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Land Type</Label>
                    <FormSelect
                        value={details.land_type}
                        onChange={e => setDetails({
                            ...details,
                            land_type: e.currentTarget.value
                        })}
                        required
                    >
                        <option value="">Choose an option</option>
                        <option value="mailo">Mailo</option>
                        <option value="freehold">Freehold</option>
                        <option value="leasehold">Leasehold</option>
                    </FormSelect>
                    <Feedback type="invalid">
                        Please select an option
                    </Feedback>
                </Col>
            </Row>


            {
                (['freehold', 'leasehold'].indexOf(details.land_type) !== -1) &&
                <Row className="g-2">
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Volume</Label>
                        <Control
                            value={details.volume}
                            onChange={e => setDetails({ ...details, volume: e.currentTarget.value })}
                            required
                        />
                        <Feedback type="invalid">
                            This field is required.
                        </Feedback>
                    </Col>
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Folio</Label>
                        <Control
                            value={details.folio}
                            onChange={e => setDetails({ ...details, folio: e.currentTarget.value })}
                            required={true}
                        />
                        <Feedback type="invalid">
                            This field is required.
                        </Feedback>
                    </Col>
                </Row>
            }


            <Row className="g-2">
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">County</Label>
                    <Control
                        value={details.county}
                        onChange={e => setDetails({ ...details, county: e.currentTarget.value })}
                        required={(details.land_type === 'mailo')}
                    />
                    <Feedback type="invalid">
                        This field is required.
                    </Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Plot</Label>
                    <Control
                        value={details.plot}
                        onChange={e => setDetails({ ...details, plot: e.currentTarget.value })}
                        required={(details.land_type === 'mailo')}
                    />
                    <Feedback type="invalid">
                        This field is required
                    </Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Block</Label>
                    <Control
                        value={details.block}
                        onChange={e => setDetails({ ...details, block: e.currentTarget.value })}
                        required={(details.land_type === 'mailo')}
                    />
                    <Feedback type="invalid">
                        This field is required
                    </Feedback>
                </Col>
            </Row>

            {(action === "create") &&
                <>
                    <h4 className="form-section-label">Attachments</h4>

                    <Row className="g-2">
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Instruction Letter</Label>
                            <FileInput
                                maxFiles={1}
                                onChange={ids => setDetails(d => ({ ...d, instruction_letter_id: ids[0] || "" }))}
                                value={[details.instruction_letter_id]}
                                allowUpload
                                placeholder='-'
                                errorText='You must upload the instruction letter.'
                            />
                            <Form.Text>
                                Upload the instruction letter. Formats accepted are PDF, excel or word documents.
                            </Form.Text>
                        </Col>
                    </Row>
                </>
            }



            <Row>
                <Col className="mt-5 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Title" : "Create Title"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}



export default TitleForm;